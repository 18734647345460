
import {
  defineComponent,
  onUnmounted,
  onMounted,
  toRefs,
  ref,
  reactive,
  watch,
} from "vue";
import { modalController } from "@ionic/vue";
import { useRouter } from "vue-router";
import RoomCard from "@/components/meeting/RoomCard.vue";
import { MeetingRoomService } from "@/services/meeting/MeetingRoomService.ts";
import moment from "moment";
import SystemUtils from "@/utils/SystemUtils";
import Bus from "@/utils/bus";
import Message from "@/models/message/Message";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import { nextTick } from "process";
import Header from "@/components/Header.vue";
import PeopleNumPage from "./modal/PeopleNum.vue";
import Utils from "@/utils/Utils";
export default defineComponent({
  name: "MeetingRoomList",
  components: {
    CardLoading,
    Header,
    RoomCard,
  },
  props: {},
  setup(props, { emit }) {
    const {} = toRefs(props);
    const router = useRouter();
    const btnSizeRight = reactive({ data: ["38px"] });
    const btnIconRight = reactive({ data: ["iconleijigongshi"] });
    const btnTextRight = reactive({ data: ["我的预定"] });
    let islist = ref(true); //是否显示加载中的标识
    let searchParams = reactive({
      orgId: "",
    });
    const currentUser: any = SystemUtils.loginUser;
    const currentUserId = currentUser.id;
    searchParams.orgId = currentUser.orgId;
    //  searchParams.orgId = "730ff3b4-b489-11ea-a744-d00dce6f2f26";
    const roomList = reactive({ data: new Array() });
    const meetingRoomService = new MeetingRoomService();
    const showNumbel = ref(false);
    const minDate = ref("");
    const maxDate = ref("");

    const startDate = ref("");
    const meetingDate = ref("");
    const peopleNum = ref("");
    const startDateModel = ref("");
    const startTimeModel = ref("");
    const isChangeDate = ref(false);
    const jumpSelect = ref(true);
    const durationList = [
      "08:00",
      "08:30",
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
      "18:00",
      "18:30",
      "19:00",
      "19:30",
      "20:00",
    ];
    const selectedEndTime = [
      "0.5小时",
      "1小时",
      "1.5小时",
      "2小时",
      "2.5小时",
      "3小时",
      "3.5小时",
      "4小时",
      "4.5小时",
      "5小时",
      "5.5小时",
      "6小时",
      "6.5小时",
      "7小时",
      "7.5小时",
      "8小时",
      "8.5小时",
      "9小时",
      "9.5小时",
      "10小时",
      "10.5小时",
      "11小时",
      "11.5小时",
      "12小时",
    ];
    const initData = () => {
      //初始化开始时间范围
      minDate.value = moment(new Date()).format("yyyy-MM-DD");
      let date = new Date();
      date.setFullYear(date.getFullYear() + 20);
      maxDate.value = date.getFullYear() + "-12-31";
      jumpSelect.value = true;
      getThisTime();
      resetPage();
    };
    const getThisTime = () => {
      let today: Date = new Date();
      if (today.getMinutes() <= 30) {
        today.setMinutes(30);
      } else {
        today.setHours(today.getHours() + 1);
        today.setMinutes(0);
      }
      startDateModel.value = moment(today).format("yyyy-MM-DD HH:mm");
    };
    //重新从第一页获取
    const resetPage = () => {
      getListRequest((res: any) => {
        roomList.data = res;
      });
    };
    const nextPage = (event: any) => {
      getListRequest(
        (result: any) => {
          roomList.data = roomList.data.concat(result);
          setTimeout(() => {
            event.target.complete();
          }, 1000);
          if (result) {
            if (result == null || result.length <= 0) {
              //  event.target.disabled = true;
            }
          }
        },
        () => {
          event.target.complete();
        }
      );
    };
    const doRefresh = (event: any) => {
      resetPage();
      setTimeout(() => {
        event.target.complete();
      }, 600);
    };
    const getListRequest = (callback: any, errCalback?: any) => {
      //    capacity:"",//参加会议人数
      // duration:"",//会议时长
      // beginTime:"",//会议室开始时间
      if (startDate.value != null && startDate.value != "") {
        searchParams = Object.assign(searchParams, {
          beginTime: startDate.value,
        });
      }
      if (meetingDate.value != null && meetingDate.value != "") {
        searchParams = Object.assign(searchParams, {
          duration: meetingDate.value.replace("小时", ""),
        });
      }
      if (peopleNum.value != null && peopleNum.value != "") {
        searchParams = Object.assign(searchParams, {
          capacity: peopleNum.value,
        });
      }
      meetingRoomService
        .getRoomList(searchParams)
        .then((res: any) => {
          islist.value = false;
          callback(res);
        })
        .catch(() => {
          islist.value = false;
          if (errCalback) {
            errCalback();
          }
        });
      // islist.value = false;
      // callback([0,1]);
    };
    const openFilter = (r: any) => {
      router.push({
        name: "MyBookListPage",
      });
    };
    const resetInput = () => {
      startDate.value = "";
      meetingDate.value = "";
      peopleNum.value = "";
      getThisTime();
      isChangeDate.value = true;
      startTimeModel.value = "";
      jumpSelect.value = true;
      resetPage();
    };
    const selectTimeList = reactive({ data: new Array() });
    //日期选择
    const selectHandle = (type: any) => {
      if (type == "date") {
        if (startDate.value != "") {
          startDateModel.value = "";
        }
        let selectOperate: any = document.getElementById(
          "select-meeting-start-date"
        );
        let el: HTMLElement = selectOperate;
        el.click();
      } else {
        let val = startDate.value.substring(startDate.value.length - 5);
        let a = durationList.length;
        let b = durationList.indexOf(val) + 1;
        let index = a - b;
        let selectedEndTimeT = selectedEndTime.concat();
        selectTimeList.data = selectedEndTimeT.slice(0, index);
        //如果当前选择的时长不在选择的列表中，清空时长
        if (meetingDate.value != "") {
          let checkIndex = selectTimeList.data.indexOf(meetingDate.value);
          if (checkIndex < 0) {
            meetingDate.value = "";
          }
        }
        nextTick(() => {
          setTimeout(() => {
            let selectOperate: any = document.getElementById(
              "select-meeting-start-time"
            );
            let el: HTMLElement = selectOperate;
            el.click();
          }, 400);
        });
      }
    };

    const bindOperateEdit = (e: any, type: any) => {
      switch (type) {
        case "time":
          meetingDate.value = e.detail.value;
          break;
        case "date":
          if (e.detail.value != null && e.detail.value != "") {
            if (isChangeDate.value) {
              isChangeDate.value = false;
            } else {
              let selectTime = moment(e.detail.value).format(
                "yyyy-MM-DD HH:mm"
              );
              let ctime = moment(new Date()).format("yyyy-MM-DD HH:mm");
              if (Date.parse(selectTime) < Date.parse(ctime)) {
                Utils.presentToastWarning("开始时间需大于当前时间");
                return false;
              } else {
                startDate.value = moment(e.detail.value).format(
                  "yyyy-MM-DD HH:mm"
                );
                selectHandle("time");
              }
            }
          }
          break;
      }
    };
    // const showNumbelHandler = () => {
    //   showNumbel.value = true;
    //   openTaskMenuModal();
    // };
    // //打开输入会议人数页面
    // const openTaskMenuModal = async () => {
    //   const modal = await modalController.create({
    //     component: PeopleNumPage,
    //     // cssClass: "task-bottom-modal-class",
    //     componentProps: {
    //       peopleNumProp: peopleNum.value,
    //     },
    //   });

    //   await modal.present();
    //   const { data } = await modal.onDidDismiss();
    //   if (data && data.peopleNum) {
    //     peopleNum.value = data.peopleNum;
    //   }
    //   showNumbel.value = false;
    // };
    const findMeeting = () => {
      if (startDate.value == "" || meetingDate.value == null) {
        getThisTime();
        startTimeModel.value = "";
        Utils.presentToastWarning("请填入开始日期和时长");
      } else {
        if (peopleNum.value != "" && parseInt(peopleNum.value) > 99999) {
          Utils.presentToastWarning("会议人数最大允许输入99999");
        } else {
          jumpSelect.value = false;
          resetPage();
        }
      }
    };

    const bindRoodBookList = (item: any) => {
      if (jumpSelect.value) {
        //无查询条件时跳转,该页面向RoomBookPage跳转时参照下面注释内容
        router.push({
          path: "/meeting-timebook",
          query: {
            roomId: item.item.id,
            roomName: item.item.name,
            capacity: item.item.capacity,
            jumpSelect: "true",
          },
        });
        // router.push({
        //   name: "RoomBookPage",
        //   params: {
        //     id: item.item.id,
        //     startDate: "2021-03-18",
        //     startTime: "11:00",
        //     selectedStartTime: 7,
        //     selectedEndTime: 25,
        //     jumpSelect: "true",
        //   },
        // });
      } else {
        let beginDate = startDate.value.substring(0, 10);
        let beginTime = startDate.value.substring(startDate.value.length - 5);

        let index = durationList.indexOf(beginTime);
        let meetingindex = selectedEndTime.indexOf(meetingDate.value);
        let endTime = durationList[index + meetingindex + 1];
        jumpSelect.value = true;
        resetInput();
        //跳会议室预定页面 传会议室id 预定日期 开始时间 结束时间
        router.push({
          name: "RoomBookPage",
          params: {
            id: item.item.id,
            startDate: beginDate,
            startTime: beginTime,
            endTime: endTime,
            jumpSelect: "false",
          },
        });
      }
    };
    onMounted(() => {
      initData();
    });
    onUnmounted(() => {});

    return {
      islist,
      router,
      btnSizeRight,
      btnIconRight,
      btnTextRight,
      roomList,
      searchParams,
      currentUserId,
      initData,
      nextPage,
      doRefresh,
      resetPage,
      bindRoodBookList,
      showNumbel,
      // showNumbelHandler,
      selectHandle,
      selectTimeList,
      minDate,
      maxDate,
      startDate,
      meetingDate,
      peopleNum,
      bindOperateEdit,
      startDateModel,
      startTimeModel,
      resetInput,
      findMeeting,
      openFilter,
    };
  },
});

import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "img" }
const _hoisted_2 = { class: "room-title" }
const _hoisted_3 = { class: "room-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: "item",
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.detailHandler(_ctx.RoomCardObj)))
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("img", {
        src: _ctx.RoomCardObj.coverImage
      }, null, 8, ["src"])
    ]),
    _createVNode("div", _hoisted_2, _toDisplayString(_ctx.RoomCardObj.name), 1),
    _createVNode("div", _hoisted_3, "容纳人数：" + _toDisplayString(_ctx.RoomCardObj.capacity), 1)
  ]))
}